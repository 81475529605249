const { algoliasearch, instantsearch } = window;

import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { connectHits, connectRange } from 'instantsearch.js/es/connectors';

const search = instantsearch({
  indexName: "calls:start_time:desc",
  routing: true,
  searchClient: instantMeiliSearch(
    "https://search.cmh.nerdfest.dev",
    "d4817f92d56a3bd11a990357a105b7bf3e3d58a85b1052db7539289d13ded1fe",
    {
      finitePagination: true
    }
  ),
  searchFunction(helper) {
	console.log(helper);
	helper.search();
  },
});

/*
The available filterable attributes are `_geo, audio_type, radios, short_name, srcList, start_time, talkgroup, talkgroup_group, talkgroup_group_tag, talkgroup_tag, units`.
*/
search.addWidgets([
  instantsearch.widgets.searchBox({
    container: "#searchbox"
  }),
  instantsearch.widgets.clearRefinements({
    container: "#clear-refinements"
  }),
  instantsearch.widgets.refinementList({
    container: "#talkgroup_group",
    showMore: true,
    attribute: "talkgroup_group"
  }),
  instantsearch.widgets.refinementList({
    container: "#talkgroup_description",
    showMore: true,
    attribute: "talkgroup_description"
  }),
  instantsearch.widgets.refinementList({
    container: "#talkgroup_group_tag",
    showMore: true,
    attribute: "talkgroup_group_tag"
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 50,
    snippetEllipsisText: "...",
    attributesToSnippet: ["transcript_plaintext:200"]
  }),
  instantsearch.widgets.infiniteHits({
    container: "#hits",
    templates: {
      item: `
        <div>
          <div class="hit-name">
            {{#helpers.highlight}}{ "attribute": "talkgroup_description" }{{/helpers.highlight}}
          </div>
          <div class="hit-description">
            {{#helpers.snippet}}{ "attribute": "transcript_plaintext" }{{/helpers.snippet}}
          </div>
          <div class="hit-audio"><audio controls src="{{raw_audio_url}}"></audio></div>
          <div class="hit-info">{{talkgroup_group}} @ {{start_time}}, geo: {{_geo}}</div>
        </div>
      `
    },
  transformItems(items) {
	return items.map(item => ({
		...item,
		start_time: formatTimestamp(item.start_time),
	}));

  }
  }),
]);

search.addWidgets([
  instantsearch.widgets.sortBy({
    container: '#sortBy',
    items: [{
      label: 'Sort by',
      value: 'calls'
    },{
      label: 'Time',
      value: 'calls:start_time:desc'
    }],
    defaultRefinement: 'Sort by'	  
  }),
])



const renderHits = (renderOptions, isFirstRender) => {
    const hits = renderOptions.hits;
    const container = document.querySelector('#hits');

    // Process and group hits by 'talkgroup_description'
    const groupedHits = hits.reduce((acc, hit) => {
      (acc[hit.talkgroup_description] = acc[hit.talkgroup_description] || []).push(hit);
      return acc;
    }, {});

    // Render the grouped hits
    container.innerHTML = Object.entries(groupedHits)
      .map(([description, groupHits]) => {
        return `
          <div class="group">
            <h2>${description}</h2>
            ${groupHits
              .map(
                hit => `
                  <div>
                    <div class="hit-name">
                      ${hit._highlightResult.talkgroup_description.value}
                    </div>
                    <img src="${hit.image}" align="left" />
                    <div class="hit-description">
                      ${hit._snippetResult.transcript_plaintext.value}
                    </div>
                    <div class="hit-info">${hit.talkgroup_group}</div>
                    <div class="hit-info">${hit.releaseDate}</div>
                  </div>
                `
              )
              .join('')}
          </div>
        `;
      })
      .join('');
  };


// Create a custom hits widget using connectHits
const customHits = connectHits(
  renderHits
);


function formatTimestamp(timestamp) {
  var date = new Date(timestamp * 1000); // Convert to milliseconds
  return date.toLocaleString();
}

//const calendarRange = connectRange((options, isFirstRendering) => {
//  if (!isFirstRendering) {
//    return;
//  }
//
//  const { refine } = options;
//
//  // eslint-disable-next-line no-new
//  new Calendar({
//    element: $('#calendar'),
//    same_day_range: true,
//    callback() {
//      const start = new Date(this.start_date).getTime();
//      const end = new Date(this.end_date).getTime();
//      const actualEnd = start === end ? end + ONE_DAY_IN_MS - 1 : end;
//
//      refine([start, actualEnd]);
//    },
//    // Some good parameters based on our dataset:
//    start_date: new Date(),
//    end_date: new Date('02/08/2024'),
//    earliest_date: new Date('02/01/2024'),
//    latest_date: new Date('02/12/2024'),
//  });
//});


// Start the search
search.start();
